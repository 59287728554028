import React, { useState } from 'react';
import { Truck, Stethoscope, Laptop, ChefHat, Home } from 'lucide-react';

// Placeholder images (these will be replaced with actual open-source images)
const placeholderImages = {
    nursing: "images/nursing-profession-2.avif",
    it: "/images/it-and-technology.avif",
    hospitality: "/images/hospitality.avif",
    elderCare: "/images/elder-care.avif",
    caregivers: "/images/caregiver.avif"
};

const RecruitmentAgencyApp = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        serviceType: '',
        message: ''
    });

    const services = [
        {
            id: 'nursing',
            name: 'Nursing Services',
            icon: <Stethoscope size={32} />,
            description: 'Professional nursing staff for hospitals, clinics, and home care. Our nurses are fully qualified, experienced, and registered with relevant nursing councils. We provide RNs, LPNs, and specialized nurses for various medical departments including emergency, pediatric, geriatric, and intensive care units. Available for both permanent placement and temporary staffing solutions.',
            requirements: ['Valid nursing license', 'Minimum 2 years experience', 'Strong clinical skills', 'Excellent communication abilities']
        },
        {
            id: 'elderCare',
            name: 'Elderly Care',
            icon: <Home size={32} />,
            description: 'Specialized elderly care providers offering comprehensive support for seniors. Our caregivers are trained in dementia care, mobility assistance, medication management, and daily living support. We focus on maintaining dignity and independence while ensuring the highest standards of care. Services include 24/7 live-in care, respite care, and specialized memory care support.',
            requirements: ['Elder care certification', 'First aid certification', 'Physical capability for assistance', 'Patience and empathy']
        },
        {
            id: 'caregivers',
            name: 'Caregivers & Nannies',
            icon: <Truck size={32} />,
            description: 'Professional caregivers and nannies providing personalized in-home care services. Our staff undergoes rigorous background checks and training in child development, safety, and emergency response. We offer full-time, part-time, and live-in options for families needing childcare, special needs care, or general household management.',
            requirements: ['Childcare certification', 'Clean background check', 'First aid certified', 'References verified']
        },
        {
            id: 'it',
            name: 'IT & Technology',
            icon: <Laptop size={32} />,
            description: 'Expert IT professionals for various technology roles including software development, system administration, cybersecurity, and project management. Our tech talent pool includes specialists in cloud computing, AI/ML, full-stack development, and DevOps. We serve startups to enterprise-level organizations with both contract and permanent positions.',
            requirements: ['Relevant technical certifications', 'Project portfolio', 'Problem-solving skills', 'Team collaboration experience']
        },
        {
            id: 'hospitality',
            name: 'Hospitality',
            icon: <ChefHat size={32} />,
            description: 'Experienced hospitality professionals including executive chefs, kitchen staff, hotel management, and event coordinators. Our candidates have extensive experience in luxury hotels, restaurants, and event venues. We provide staff for both permanent positions and special events, ensuring the highest standards of service excellence.',
            requirements: ['Industry certification', 'Health & safety training', 'Customer service excellence', 'Team management skills']
        }
    ];


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form submission started');

        // Create FormData object
        const formDataObj = new FormData();
        Object.keys(formData).forEach(key => {
            formDataObj.append(key, formData[key]);
        });
        formDataObj.append('form-name', 'contact');

        try {
            console.log('Submitting form data:', formData);
            const response = await fetch('/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: encode({
                    'form-name': 'contact',
                    ...formData
                })
            });

            if (response.ok) {
                alert('Thank you for your inquiry! We will contact you soon.');
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    serviceType: '',
                    message: ''
                });
            } else {
                throw new Error(`Form submission failed: ${response.status}`);
            }
        } catch (error) {
            console.error('Detailed error:', {
                message: error.message,
                stack: error.stack
            });
            alert('Sorry, there was an error submitting the form. Please try again.');
        }
    };

    return (
        <div className="min-h-screen bg-gray-100">
            {/* Header */}
            <header className="bg-blue-600 text-white p-4 shadow-md">
                <div className="container mx-auto flex justify-between items-center">
                    <h1 className="text-2xl font-bold"> Imagine 8 Recruitment Agency</h1>
                    <nav>
                        <ul className="flex space-x-4">
                            <li><a href="#services" className="hover:text-blue-200">Services</a></li>
                            <li><a href="#contact" className="hover:text-blue-200">Contact</a></li>
                        </ul>
                    </nav>
                </div>
            </header>

            {/* Hero Section */}
            <section className="bg-blue-500 text-white text-center py-16">
                <h2 className="text-4xl font-bold mb-4">Find Your Perfect Staffing Solution</h2>
                <p className="text-xl mb-8">Connecting Talent with Opportunity Across Multiple Sectors</p>
            </section>

            {/* Services Section */}
            <section id="services" className="container mx-auto py-16 px-4">
                <h2 className="text-3xl font-bold text-center mb-12">Our Services</h2>
                <div className="grid md:grid-cols-3 gap-8">
                    {services.map((service) => (
                        <div
                            key={service.id}
                            className="bg-white rounded-lg shadow-md p-8 hover:shadow-xl transition-all"
                        >
                            <div className="flex items-center mb-4">
                                <div className="text-blue-600 mr-4">{service.icon}</div>
                                <h3 className="text-2xl font-semibold">{service.name}</h3>
                            </div>
                            <p className="text-gray-600 mb-4">{service.description}</p>
                            <img
                                src={placeholderImages[service.id]}
                                alt={service.name}
                                className="mt-6 rounded-md w-full h-48 object-cover"
                            />
                        </div>
                    ))}
                </div>
            </section>

            {/* Contact Form */}
            <section id="contact" className="bg-gray-200 py-16">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12">Contact Us</h2>
                    <form
                        name="contact"
                        method="POST"
                        action="/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={handleSubmit}
                        className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-md"
                    >
                        <input type="hidden" name="form-name" value="contact"/>
                        <p className="hidden">
                            <label>
                                Don't fill this out if you're human: <input name="bot-field"/>
                            </label>
                        </p>
                        <div className="mb-4">
                            <label htmlFor="name" className="block mb-2 font-medium">Full Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="block mb-2 font-medium">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="phone" className="block mb-2 font-medium">Phone</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="serviceType" className="block mb-2 font-medium">Service Type</label>
                            <select
                                id="serviceType"
                                name="serviceType"
                                value={formData.serviceType}
                                onChange={handleInputChange}
                                required
                                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            >
                                <option value="">Select a Service</option>
                                {services.map((service) => (
                                    <option key={service.id} value={service.id}>
                                        {service.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-6">
                            <label htmlFor="message" className="block mb-2 font-medium">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleInputChange}
                                required
                                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                rows="4"
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white py-3 rounded hover:bg-blue-700 transition-colors focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                            Send Inquiry
                        </button>
                    </form>
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-blue-800 text-white py-8 text-center">
                <p>&copy; 2024 Imagine 8 Recruitment Agency. All Rights Reserved.</p>
            </footer>
        </div>
    );
};

export default RecruitmentAgencyApp;